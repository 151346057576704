// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import Genie from "../assets/images/GenieDownload.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Contact(props) {
    const [name, setName] = useState("");
    const [orgname, setOrgName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [question, setQuestion] = useState("");
    const [selectedPlanDetails, setSelectedPlanDetails] = useState(props.plan.selectedPlanDetails);
    const [plan, setPlan] = useState(selectedPlanDetails.name);

    const handleSubmit = (e) => {
      e.preventDefault();
  
      const formData = {
        name,
        orgname,
        phone,
        email,
        plan,
        question,
      };

     


    // send to backend
      fetch("https://stage-api.bettermystay.com/api/v1/contactus", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "5f4dcc3b5aa765d61d8327deb882cf99"
        },
        body: JSON.stringify({  // Convert the object to a JSON string
          'email': email,
          'phone': phone,
          'name': name,
          'orgName': orgname,
          'plan': plan,
          'message': question,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Response from server:", data);
          if (data.message ==='Added!') {
            setName("")
            setOrgName("")
            setPhone("")
            setPlan("")
            setEmail("")
            setQuestion("")
            toast.success("Thanks for Contacting Us. Our team will soon get back to you! ");
          }
        })
        .catch((error) => {
          console.error("Error sending request:", error);
          toast.error("An error occurred. Please Try Again.");
        });  
    
    };
 

  return (
    <>
      <div
        className="flex flex-col sm:flex-row h-contactmobile md:h-full  sm:h-80 w-full justify-between"
      >
        <div className="h-56 items-center justify-center sm:h-3/4 sm:w-1/2 flex sm:items-center sm:justify-center">
        <div className="absolute left-1/2 transform -translate-x-1/2 top-20 pt-30">
          <h2 className="text-white text-2xl sm:text-3xl lg:text-4xl 2xl:text-5xl font-bold mb-4 text-center px-4">
            Contact Us
          </h2>
          <p className="text-white text-sm sm:text-base lg:text-lg 2xl:text-xl text-center mb-4">
            Registered Email : connect@bettermystay.com
          </p>
          <p className="text-white text-sm sm:text-base lg:text-lg 2xl:text-xl text-center mb-4">
            Contact Number : +91 8395818779
          </p>
        </div>
      </div>
      <div className="h-56 items-center justify-center sm:h-3/4 sm:w-1/2 flex sm:items-center sm:justify-center">
        <div className="absolute 2xl:left-36 left-14 lg:left-5 xl:left-36 top-30">
          <img src={Genie} className="h-32 sm:h-56 lg:h-96 2xl:h-genie"></img>
        </div>
      </div>
        <div className="h-3/8 sm:h-3/4 sm:w-1/2 flex justify-center align-middle items-center">
          <form style={{height:600}}  onSubmit={handleSubmit} className="p-3 sm:space-y-2 justify-center flex flex-col sm:m-6 bg-golden/50 md:px-7 md:h-72 lg:px-10  lg:h-3/4 2xl:h-3/6 rounded-2xl">
            <div className=" flex justify-center space-x-2">
              <div>
                <label htmlFor="name" className="block text-white">
                  Name
                </label>

                <input
                  type="text"
                  value={name}
                  className="w-56 sm:w-60 xl:w-80 h-8 rounded-md p-2"
                  placeholder="Your Good Name "
                  onChange={(e) => setName(e.target.value)}
                  required
                ></input>
              </div>
            
            </div>
            <div className=" flex justify-center space-x-2">
            <div>
                <label htmlFor="name" className="block text-white">
                  Organization Name
                </label>

                <input
                  type="text"
                  value={orgname}
                  className="w-56 sm:w-60 xl:w-80 h-8 rounded-md p-2"
                  placeholder="Your Organization Name "
                  onChange={(e) => setOrgName(e.target.value)}
                  required
                ></input>
              </div>
            
            </div>
            <div className=" flex justify-center space-x-2">
              <div>
                <label htmlFor="name" className="block text-white">
                  Phone Number
                </label>

                <input
                  type="text"
                  value={phone}
                  className="w-56 sm:w-60 xl:w-80 h-8 rounded-md p-2"
                  placeholder="Your Contact Number "
                  onChange={(e) => setPhone(e.target.value)}
                  required
                ></input>
              </div>
            
            </div>
            <div className="flex justify-center space-x-2 ">
              <div>
                <label htmlFor="name" className="block text-white">
                  Email
                </label>

                <input
                  type="email"
                  value={email}
                  className="w-56 sm:w-60 xl:w-80 h-8 rounded-md p-2"
                  placeholder="Where can we contact you "
                  onChange={(e) => setEmail(e.target.value)}
                  required 
                ></input>
              </div>
            </div>
            <div className=" flex justify-center space-x-2">
              <div>
                <label htmlFor="name" className="block text-white">
                 Selected Subscription Type
                </label>

              <select
                value={plan}
                className="w-56 sm:w-60 xl:w-80 h-8 rounded-md p-2"
                onChange={(e) => setPlan(e.target.value)}
              >
                <option value="">Select a Plan</option>
                <option value="Custom Subscription">Custom Subscription</option>
                <option value="Quarterly">Quaterly Subscription</option>
                <option value="Semi-Annually">Semi Annually Subscription</option>
                <option value="Annually">Annually Subscription</option>
              </select>
              </div>
            
            </div>

            
            <div className="flex  justify-center space-x-2 ">
              <div>
                <label htmlFor="name" className="block text-white">
                  Your Question
                </label>

                <textarea
                 value={question}
                  className="w-56 sm:w-60 xl:w-80 h-16 rounded-md p-2 resize-none"
                  placeholder="We would love to hear to answer all your Questions "
                  onChange={(e) => setQuestion(e.target.value)}
                  required
                ></textarea>
              </div>
            </div>
           
      <div className="flex justify-center w-full xl:pt-6 ">
              <button
                type="submit"
                className="bg-golden  w-28 lg:h-10 xl:h-10
                xl:w-32 2xl:h-10 rounded-full text-white hover:bg-white hover:text-golden"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "5rem" }}
      />
      </div>

    </>
  );
}

export default Contact;
