// eslint-disable-next-line no-unused-vars
import React,{ useEffect, useState} from "react";
import "./container.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import DOMPurify from 'dompurify';

function TermsAndConditionsStealth() {

    const [data, setData] = useState("");

  useEffect(() => {
    const element = document.documentElement;
    element.style.scrollBehavior = "smooth";
    element.scrollTo(0, 0);
  }, []);

  // GET FROM  backend
  fetch("https://stage-api.bettermystay.com/api/v1/website/getTermsAndCond", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "5f4dcc3b5aa765d61d8327deb882cf99"
    },
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Response from server:", data.data.data);
      setData(data.data.data)
    })
    .catch((error) => {
      console.error("Error sending request:", error);
    });  
  
  const temp = DOMPurify.sanitize(data);

  return (
    <>
      <div className="bg-container-tnc">
        <div className="bg-image-tnc1"></div>
        <div
          className="bg-text flex-col absolute top-20 bg-golden/30 p-2 m-8 
        md:mx-24
        lg:mx-28
        xl:mx-36 
        2xl:mx-56 "
        >
          <div className=" text-center">
            <h1 className="text-base text-white sm:text-4xl font-bold xl:text-5xl pt-5 ">
              Terms Of Use BetterMyStay
            </h1>
          </div>
          <div className="wrapper" >
          <div
              dangerouslySetInnerHTML={{ __html: temp }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsAndConditionsStealth;
